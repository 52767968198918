import { arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../../firebase_config/firebase_config'
import { Team } from '../../../types'
import { omit } from 'lodash'

export const getTeamsByIds = async (teamIDs): Promise<Team[]> => {
    try {
        const q = query(
            collection(db, `teams`),
            where(
                '__name__',
                'in',
                teamIDs.map((t) => t.id)
            )
        )

        const res = await getDocs(q)
        return res.docs?.map((d) => ({ ...(d.data() as Team), id: d.id }))
    } catch (error) {
        console.log(error)
    }
}

export const getTeam = async (teamId: string): Promise<Team> => {
    const teamRef = doc(db, 'teams', teamId)

    try {
        const snapshot = await getDoc(teamRef)
        return { id: snapshot.id, ...(snapshot.data() as Team) }
    } catch (error) {
        console.log(error.message)
    }
}

export const revokeTeamAccess = async (teamId, brandId) => {
    const teamRef = doc(db, 'teams', teamId)
    const brandRef = doc(db, 'brands', brandId)

    try {
        const teamDoc = await getDoc(teamRef)
        const brandDoc = await getDoc(brandRef)

        const teamData = teamDoc.data()
        const brandData = brandDoc.data()

        const newTeamBrands = teamData.brands.filter((b) => b.id !== brandId)
        const newBrandTeams = brandData.teams.filter((t) => t.id !== teamId)

        const teamUsers = teamData.users.map((u) => u.id)
        const newBrandAssignedUsers = brandData.assignedUsers.filter((u) => !teamUsers.includes(u.id))
        const newBrandAccessRequests = brandData.accessRequests.filter((u) => !teamUsers.includes(u.id))

        await updateDoc(teamRef, { brands: newTeamBrands })
        await updateDoc(brandRef, {
            teams: newBrandTeams,
            assignedUsers: newBrandAssignedUsers,
            accessRequests: newBrandAccessRequests,
        })
    } catch (error) {
        console.log(error)
    }
}

export const saveTeam = async (team) => {
    const teamRef = doc(db, 'teams', team.id)

    try {
        await updateDoc(teamRef, team)
    } catch (error) {
        console.log(error)
    }
}

export const getTeamAuditionFormSettings = async (auditionId) => {
    try {
        const res = await getDocs(query(collection(db, 'teams'), where('auditionFormSettings.uuid', '==', auditionId)))
        return res.docs?.[0].data() || null
    } catch (error) {
        throw new Error(error)
    }
}

export const firestoreUpdateTeamName = async (teamId, name) => {
    const teamRef = doc(db, 'teams', teamId)

    try {
        await updateDoc(teamRef, { name })
    } catch (error) {
        console.log(error)
    }
}

export const acceptInviteToBrand = async (params: { teamId: string; brandId: string; notificationId: string }) => {
    const { teamId, notificationId, brandId } = params

    let targetNotificationRef
    let targetNotificationSnapshot

    targetNotificationRef = doc(db, 'teams', teamId, 'notifications', notificationId)
    targetNotificationSnapshot = await getDoc(targetNotificationRef)

    if (!targetNotificationSnapshot.exists()) {
        await deleteDoc(targetNotificationRef)
        return
    }

    const targetTeamRef = doc(db, 'teams', teamId)
    const targetTeamData = (await getDoc(targetTeamRef)).data()

    const targetTeamBrandIndex = targetTeamData.brands.findIndex((el) => el.id === brandId)

    const updatedTeamBrands = [...targetTeamData.brands]

    updatedTeamBrands.splice(targetTeamBrandIndex, 1, omit(updatedTeamBrands[targetTeamBrandIndex], 'isPending'))

    await updateDoc(targetTeamRef, {
        brands: updatedTeamBrands,
    })

    const targetBrandRef = doc(db, 'brands', brandId)
    const targetBrandData = (await getDoc(targetBrandRef)).data()

    const targetBrandTeamIndex = targetBrandData.teams.findIndex((el) => el.id === teamId)

    const updatedBrandTeams = [...targetBrandData.teams]

    updatedBrandTeams.splice(targetBrandTeamIndex, 1, omit(updatedBrandTeams[targetBrandTeamIndex], 'isPending'))

    await updateDoc(targetBrandRef, {
        teams: updatedBrandTeams,
    })

    await deleteDoc(targetNotificationRef)
}

export const dismissInviteToBrand = async (params: { teamId: string; brandId: string; notificationId: string }) => {
    const { teamId, notificationId, brandId } = params

    let targetNotificationRef
    let targetNotificationSnapshot

    targetNotificationRef = doc(db, 'teams', teamId, 'notifications', notificationId)
    targetNotificationSnapshot = await getDoc(targetNotificationRef)

    if (!targetNotificationSnapshot.exists()) {
        await deleteDoc(targetNotificationRef)
        return
    }

    const targetTeamRef = doc(db, 'teams', teamId)
    const targetTeamData = (await getDoc(targetTeamRef)).data()
    const targetTeamBrandsIndex = targetTeamData.brands.findIndex((el) => el.id === brandId)

    const updatedTeamBrands = [...targetTeamData.brands]

    updatedTeamBrands.splice(targetTeamBrandsIndex, 1)

    await updateDoc(targetTeamRef, {
        brands: updatedTeamBrands,
    })

    const targetBrandRef = doc(db, 'brands', brandId)
    const targetBrandData = (await getDoc(targetBrandRef)).data()
    const targetBrandTeamsIndex = targetTeamData.brands.findIndex((el) => el.id === teamId)

    const updatedBrandTeams = [...targetBrandData.teams]

    updatedBrandTeams.splice(targetBrandTeamsIndex, 1)

    await updateDoc(targetBrandRef, {
        teams: updatedBrandTeams,
    })

    await deleteDoc(targetNotificationRef)
}
