import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase_config/firebase_config";

export async function getContentById(ids, path) {
    // don't run if there aren't any ids or a path for the collection
    if (!ids || !ids.length || !path) return [];
  
    const collectionPath = collection(db, path);
    const batches = [];
  
    while (ids.length) {
      // firestore limits batches to 10
      const batch = ids.splice(0, 10);

      const q = query(collectionPath, where('__name__', 'in', [...batch]))
  
      // add the batch request to to a queue
      batches.push(
        getDocs(q).then(results => results.docs.map(result => ({ /* id: result.id, */ ...result.data() }) ))
      )
    }
  
    // after all of the data is fetched, return it
    return Promise.all(batches)
      .then(content => content.flat());
  }