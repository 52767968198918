import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

interface NavContextData {
    expandNav: boolean
    changeExpandNav: (value: boolean) => void
}

export const NavContext = createContext<NavContextData>(null!)

export const useNavContext = () => useContext(NavContext)

const NavContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const isNarrowScreen = useMediaQuery({ query: '(max-width: 1765px)' })
    const prefferedExpandNav = Boolean(
        (typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem('expandNav'))) || true
    )

    const [expandNav, setExpandNav] = useState<boolean>(!isNarrowScreen && prefferedExpandNav)

    const changeExpandNav = (value: boolean) => {
        setExpandNav(value)

        localStorage.setItem('expandNav', JSON.stringify(value))
    }

    const value = {
        expandNav,
        changeExpandNav,
    }

    return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}

export default NavContextProvider
