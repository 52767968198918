import { createContext, ReactNode, useContext, useState, FC, useRef } from 'react'
import { TeamLibraryTalentData } from '../types'

interface GlobalContextData {
    dismissed: boolean
    closeBanner: () => void
    headerTitleOverwrite: string
    setHeaderTitleOverwrite: (title: string) => void
    alert: string
    handleSetAlert: (alertString: string) => void
}

export const GlobalContext = createContext<GlobalContextData>(null!)

export const useGlobalContext = () => useContext(GlobalContext)

const GlobalContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [dismissed, setDismissed] = useState(false)
    const [headerTitleOverwrite, setHeaderTitleOverwrite] = useState('')
    const [alert, setAlert] = useState('')

    const handleSetAlert = (alertString: string) => setAlert(alertString)

    const closeBanner = () => setDismissed(true)

    const value = {
        dismissed,
        closeBanner,
        headerTitleOverwrite,
        setHeaderTitleOverwrite,
        alert,
        handleSetAlert,
    }

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

export default GlobalContextProvider
