import { TeamLibraryTalentData } from '../../../types'
import {
    doc,
    collection,
    updateDoc,
    getDoc,
    addDoc,
    setDoc,
    getDocs,
    collectionGroup,
    query,
    where,
} from 'firebase/firestore'
import { db } from '../../../firebase_config/firebase_config'
import { TalentData } from '../../../types'

export const getTalent = async (talentId) => {
    const ref = doc(collection(db, `talent`), talentId)
    try {
        const talent = await getDoc(ref)
        return { ...talent.data(), id: talent.id } as TalentData
    } catch (error) {
        console.log(error)
    }
}

export const updateTalentProfile = async (talent, talentId) => {
    const ref = doc(collection(db, `talent`), talentId)
    try {
        await updateDoc(ref, talent)
    } catch (error) {
        console.log(error)
    }
}

export const updateTeamTalent = async (talent, talentId, team) => {
    const ref = doc(collection(db, `teams`, team, `talentLibrary`), talentId)
    try {
        await updateDoc(ref, talent)
    } catch (error) {
        console.log(error)
    }
}

export const tagTeamLibraryTalent = async (teamId, talentId, tags: string[]) => {
    const ref = doc(collection(db, `teams`, teamId, `talentLibrary`), talentId)
    try {
        await updateDoc(ref, {
            tags: tags,
        })
    } catch (error) {
        console.log(error)
    }
}

export const addBrandTalent = async (brandId, talent) => {
    const ref = doc(db, `brands`, brandId, `talentSuggestions`, talent.id)
    if ('exists' in talent) {
        //@ts-ignore
        delete talent.exists
    }
    if ('ref' in talent) {
        //@ts-ignore
        delete talent.ref
    }
    try {
        await setDoc(ref, { ...talent, brand: brandId, appliedAt: new Date() })
    } catch (error) {
        console.log(error)
    }
}

export const getTalentByEmail = async (email) => {
    const q = query(collection(db, `talent`), where('email', '==', email))
    try {
        const res = await getDocs(q)
        if (res.docs.length === 0) {
            return null
        } else {
            return res.docs[0].data()
        }
    } catch (error) {
        console.log(error)
    }
}

export const getBrandTalent = async (brandId) => {
    const ref = collection(db, `brands`, brandId, `talentSuggestions`)
    try {
        const res = await getDocs(ref)
        return res.docs.map((d) => ({ ...d.data(), id: d.id }))
    } catch (error) {
        console.log(error)
    }
}

export const newTalentProfile = async (talent) => {
    const ref = collection(db, `talent`)
    try {
        const docRef = await addDoc(ref, talent)

        updateDoc(docRef, {
            id: docRef.id,
        })

        return docRef.id
    } catch (error) {
        console.log(error)
    }
}

export const sendTalentApplication = async (teamId, talentData) => {
    const libData: TeamLibraryTalentData = {
        id: talentData.id,
        appliedAt: new Date(),
        status: 'pending',
        comments: [],
        tags: [],
        previouslyUsed: false,
        profile: talentData,
    }

    const ref = collection(db, `teams`, teamId, `talentLibrary`)
    try {
        await setDoc(doc(ref, talentData.id), libData)
    } catch (error) {
        console.log(error)
    }
}

export const getTalentBrandEntries = async (talentId) => {
    const ref = query(collectionGroup(db, `talentSuggestions`), where(`id`, `==`, talentId))
    try {
        const res = await getDocs(ref)
        return res.docs.map((d) => ({ ...d.data() }))
    } catch (error) {
        console.log(error)
    }
}

export const updateBrandTalent = async (brandId, talentId, updatedFields) => {
    const ref = doc(db, `brands`, brandId, `talentSuggestions`, talentId)
    try {
        await updateDoc(ref, updatedFields)
    } catch (error) {
        console.log(error)
    }
}

export const getBrandTalentById = async (brandId, talentId) => {
    const ref = doc(db, `brands`, brandId, `talentSuggestions`, talentId)
    try {
        const res = await getDoc(ref)
        return { ...res.data(), id: res.id }
    } catch (error) {
        console.log(error)
    }
}

export const changeStatusTeamLibraryTalent = async (teamId, talentId, status) => {
    const ref = doc(collection(db, `teams`, teamId, `talentLibrary`), talentId)
    try {
        await updateDoc(ref, {
            status: status,
        })
    } catch (error) {
        console.log(error)
    }
}
