import { createContext, ReactNode, useContext, useState, FC, useEffect } from 'react'
import {
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    getAuth,
    User,
    UserCredential,
    signInWithCustomToken,
} from 'firebase/auth'
import { auth, functions } from '../../../firebase_config/firebase_config'
import { useRouter } from 'next/router'
import { getUser, updateUser } from '../../../api/firestore/users'
import { UserData } from '../../../types'
import { getClientSubscriptions } from '../../../api/firestore/clients'
import LogRocket from 'logrocket'
import { httpsCallable } from 'firebase/functions'
import { DocumentData, useDoc } from '@tatsuokaniwa/swr-firestore'

interface IAuthData {
    currentUser: User | null
    userDB: DocumentData<UserData> | null
    claims: any
    loadingAuth: boolean
    userCredFromSlack: UserCredential
    slackLoginRedirect: boolean
    slackLoginRedirectDone: () => void
    handleSignIn: (email: string, password: string) => Promise<void>
    handleSignInWithCustomToken: (token: string) => Promise<void>
    handleSignOut: () => Promise<void>
    loginWithSlack: () => Promise<UserCredential>
    handleUpdatePassword: (newPassword: string) => void
    fromViscap: boolean
    subscriptions: any[]
}

export const AuthContext = createContext<IAuthData>(null!)

export const useAuth = () => {
    return useContext(AuthContext)
}

interface VerificationResult {
    success: string
    key: string
    companyName: string
    clientId: string
}

interface AddRoleResult {
    success: string
}

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const [loadingAuth, setLoadingAuth] = useState<boolean>(true)
    const [userRole, setUserRole] = useState<string>('')
    const [userCredFromSlack, setUserCredFromSlack] = useState<UserCredential>()
    const [slackLoginRedirect, setSlackLoginRedirect] = useState(false)
    const [fromViscap, setFromViscap] = useState(false)
    // const [userDB, setUserDB] = useState<UserData>(null)
    const [claims, setClaims] = useState({})
    const [subscriptions, setSubscriptions] = useState([])

    const { data: userDB = null } = useDoc<UserData>(
        currentUser
            ? {
                  path: `users/${currentUser.uid}`,
              }
            : null
    )

    const router = useRouter()

    useEffect(() => {
        const fetchSubscriptions = async () => {
            const subs = await getClientSubscriptions(currentUser.uid)
            setSubscriptions(subs)
        }

        if (currentUser) {
            fetchSubscriptions()
        }
    }, [currentUser])

    useEffect(() => {
        const subscriptionStatus = subscriptions?.find(
            (sub) => (sub.role == 'owner' || sub.role == 'member') && sub.status == 'active'
        )
            ? 'active'
            : 'inactive'
        const setSubStatus = async () => {
            console.log(subscriptionStatus)
            // setUserDB({ ...userDB, subscriptionStatus: subscriptionStatus })
            await updateUser(userDB.uid, { subscriptionStatus: subscriptionStatus })
        }
        if (userDB && subscriptions && subscriptions.length > 0 && userDB.subscriptionStatus !== subscriptionStatus) {
            setSubStatus()
        }
    }, [subscriptions, userDB])

    const handleSignInWithCustomToken = async (token: string) => {
        try {
            await signInWithCustomToken(auth, token)
        } catch (err) {
            console.log(err)
            throw new Error('There was an error attempting to sign you in')
        }
    }

    const handleSignIn = async (email: string, password: string) => {
        setLoadingAuth(true)
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (err) {
            if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
                throw new Error('There was an error attempting to sign you in: Wrong email or password.')
            }
            throw new Error('There was an error attempting to sign you in')
        }
    }

    const handleUpdatePassword = async (newPassword: string) => {
        try {
            const updatePassword = httpsCallable(functions, 'changePassword')
            await updatePassword({ uid: currentUser.uid, newPassword: newPassword })
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleSignOut = async () => {
        try {
            await signOut(auth)
            setCurrentUser(null)
            setUserCredFromSlack(null)
        } catch (err) {
            console.log(err)
            throw new Error('There was an issue signing you out')
        }
    }

    const loginWithSlack = async () => {
        const provider = new OAuthProvider('oidc.slack')

        provider.addScope('openid')
        provider.addScope('email')
        provider.addScope('profile')

        const credential = await signInWithPopup(auth, provider)
        setUserCredFromSlack(credential)

        return credential
    }

    useEffect(() => {
        setLoadingAuth(true)

        return auth.onAuthStateChanged(async (user) => {
            const setNoneUser = () => {
                console.log('no user!')
                setCurrentUser(null)
                // setUserDB(null)
                setUserRole('')
                setLoadingAuth(false)
                if (router.pathname.includes('admin')) {
                    router.push('/login')
                }
            }

            if (auth.currentUser?.isAnonymous) {
                console.log('anonymous user')
                setLoadingAuth(false)
                return
            }

            if (user) {
                try {
                    const userDBData: any = await getUser(user.uid)
                    if (userDBData) {
                        if (!('email' in userDBData)) {
                            setNoneUser()
                            setSlackLoginRedirect(true)
                            return
                        }

                        const fromViscap_ = userDBData.email.endsWith('@viscapmedia.com')
                        // const fromNullSquad = userDBData.email.endsWith('@null-squad.com')
                        setFromViscap(fromViscap_)

                        // if (process.env.GIT_BRANCH === 'develop') {
                        //     if (!fromViscap_ && !fromNullSquad) {
                        //         throw new Error('Company email is required to access preview version')
                        //     }
                        // }

                        setCurrentUser(user)

                        const idTokenResult = await auth.currentUser.getIdTokenResult(true)
                        const claims = idTokenResult.claims

                        setClaims(claims)

                        // setUserDB(userDBData)
                        setUserRole(userDBData.role)
                        sessionStorage.setItem('currentUserEmail', userDBData.email)
                        sessionStorage.setItem('currentUserId', userDBData.uid)
                        LogRocket.identify(userDBData.uid, { email: userDBData.email })
                        console.log('notice: user is logged in')

                        setLoadingAuth(false)
                    }
                } catch (err) {
                    console.log(err)
                    // throw new Error('Could not retrieve your information from our records')
                    if (router.pathname.includes('admin')) {
                        router.push('/login')
                    }
                }
            } else {
                setNoneUser()
            }
        })
    }, [router])

    const slackLoginRedirectDone = () => setSlackLoginRedirect(false)

    const value = {
        currentUser,
        userDB,
        claims,
        loadingAuth,
        userCredFromSlack,
        slackLoginRedirect,
        slackLoginRedirectDone,
        handleSignIn,
        handleSignInWithCustomToken,
        handleSignOut,
        loginWithSlack,
        handleUpdatePassword,
        fromViscap,
        subscriptions,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
