import { db } from '../../../firebase_config/firebase_config'
import {getDocs, doc, getDoc, query, where, collection, updateDoc} from "firebase/firestore"
import {ClientData} from "../../../types";

export const getActiveClients = async () => {
    const q = query(collection(db, 'clients'), where('currentContract','!=',''))

    try {
        const res = await getDocs(q)
        return res.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    } catch (error: any) {
        throw new Error(error)
    }
}

export const getClient = async (clientId: string) => {
    const clientRef = doc(db, 'clients', clientId)
    try {
        const res = await getDoc(clientRef)
        return { ...res.data(), id: res.id }
    } catch (error: any) {
        throw new Error(error)
    }
}

export const updateClient = async (clientId: string, clientObj: ClientData) => {
    const clientRef = doc(db, 'clients', clientId)
    try {
        await updateDoc(clientRef, clientObj)
    } catch (error: any) {
        throw new Error(error)
    }
}

export const getClientSubscriptions = async (clientId:string) => {
    let res = []
    
    const q = collection(db, 'users/' + clientId + '/subscriptions')

    try {
        const snapshot = await getDocs(q)
        snapshot.forEach((d) => res.push({ id: d.id, ...d.data() }))
    }
    catch (error) {
        console.log(error.message)
    }

    return res
}