import { AnyAbility, createMongoAbility } from '@casl/ability'
import { FC, ReactNode, createContext, useEffect, useState, useContext } from 'react'
import { usePermissions } from './PermissionContext'
import { Permissions } from '../../../types'
import { useAuth } from './AuthContext'
import { createContextualCan } from '@casl/react'
import { newUserAbility } from '../../../data/abilities'
import Spinner from '../../../components/Admin/utils/Spinner'

interface IAbilityContext extends AnyAbility {}

export const AbilityContext = createContext<IAbilityContext>(null!)

export const Can = createContextualCan(AbilityContext.Consumer)

export const useAbilityCtx = () => useContext(AbilityContext)

export const defineRulesForUser = (uid: string, perm: Permissions) => {
    let rules: any[] = [
        {
            inverted: !perm.brand.info.update,
            action: 'update',
            subject: 'brand',
            fields: ['info'],
        },
        {
            inverted: !perm.brand.info.read,
            action: 'read',
            subject: 'brand',
            fields: ['info'],
        },
        {
            inverted: !perm.brand.creatives.create,
            action: 'create',
            subject: 'brand',
            fields: ['creatives'],
        },
        {
            inverted: !perm.brand.creatives.update,
            action: 'delete',
            subject: 'brand',
            fields: ['creatives'],
        },
        {
            inverted: !perm.brand.creatives.approve,
            action: 'approve',
            subject: 'brand',
            fields: ['creatives'],
        },
        {
            inverted: !perm.brand.casting.approve,
            action: 'approve',
            subject: 'brand',
            fields: ['casting'],
        },
        {
            inverted: !perm.brand.casting.read,
            action: 'read',
            subject: 'brand',
            fields: ['casting'],
        },
        {
            inverted: !perm.brand.casting.submit,
            action: 'submit',
            subject: 'brand',
            fields: ['casting'],
        },
        {
            inverted: !perm.brand.products.create,
            action: 'create',
            subject: 'brand',
            fields: ['products'],
        },
        {
            inverted: !perm.brand.products.read,
            action: 'read',
            subject: 'brand',
            fields: ['products'],
        },
        {
            inverted: !perm.brand.products.update,
            action: 'update',
            subject: 'brand',
            fields: ['products'],
        },
        {
            inverted: !perm.brand.products.delete,
            action: 'delete',
            subject: 'brand',
            fields: ['products'],
        },
        {
            inverted: !perm.brand.gwtb.create,
            action: 'create',
            subject: 'brand',
            fields: ['gwtb'],
        },
        {
            inverted: !perm.brand.gwtb.read,
            action: 'read',
            subject: 'brand',
            fields: ['gwtb'],
        },
        {
            inverted: !perm.brand.gwtb.update,
            action: 'update',
            subject: 'brand',
            fields: ['gwtb'],
        },
        {
            inverted: !perm.brand.gwtb.delete,
            action: 'delete',
            subject: 'brand',
            fields: ['gwtb'],
        },
        {
            inverted: !perm.brand.teams.create,
            action: 'create',
            subject: 'brand',
            fields: ['teams'],
        },
        {
            inverted: !perm.brand.teams.delete,
            action: 'delete',
            subject: 'brand',
            fields: ['teams'],
        },
        {
            inverted: !perm.brand.teams.update,
            action: 'update',
            subject: 'brand',
            fields: ['teams'],
        },
        {
            inverted: !perm.brand.gwtb.create,
            action: 'create',
            subject: 'brand',
            fields: ['media'],
        },
        {
            inverted: !perm.brand.media.read,
            action: 'read',
            subject: 'brand',
            fields: ['media'],
        },
        {
            inverted: !perm.brand.media.update,
            action: 'update',
            subject: 'brand',
            fields: ['media'],
        },
        {
            inverted: !perm.brand.media.delete,
            action: 'delete',
            subject: 'brand',
            fields: ['media'],
        },
        {
            inverted: !perm.brand.gwtb.create,
            action: 'create',
            subject: 'brand',
            fields: ['ideation'],
        },
        {
            inverted: !perm.brand.ideation.delete,
            action: 'delete',
            subject: 'brand',
            fields: ['ideation'],
        },
        {
            inverted: !perm.brand.ideation.submit,
            action: 'submit',
            subject: 'brand',
            fields: ['ideation'],
        },
        {
            inverted: !perm.brand.ideation.approve,
            action: 'approve',
            subject: 'brand',
            fields: ['ideation'],
        },
        {
            inverted: !perm.team.info.read,
            action: 'read',
            subject: 'team',
            fields: ['info'],
        },
        {
            inverted: !perm.team.info.update,
            action: 'update',
            subject: 'team',
            fields: ['info'],
        },
        {
            inverted: !perm.team.billing.manage,
            action: 'manage',
            subject: 'team',
            fields: ['billing'],
        },
        {
            inverted: !perm.team.members.create,
            action: 'create',
            subject: 'team',
            fields: ['members'],
        },
        {
            inverted: !perm.team.members.delete,
            action: 'delete',
            subject: 'team',
            fields: ['members'],
        },
        {
            inverted: !perm.team.members.update,
            action: 'update',
            subject: 'team',
            fields: ['members'],
        },
        {
            inverted: !perm.team.brands.create,
            action: 'create',
            subject: 'team',
            fields: ['brands'],
        },
        {
            inverted: !perm.team.brands.delete,
            action: 'delete',
            subject: 'team',
            fields: ['brands'],
        },
        {
            inverted: !perm.team.talent.approve,
            action: 'approve',
            subject: 'team',
            fields: ['talent'],
        },
        {
            inverted: !perm.team.talent.read,
            action: 'read',
            subject: 'team',
            fields: ['talent'],
        },
        {
            inverted: !perm.team.talent.submit,
            action: 'submit',
            subject: 'team',
            fields: ['talent'],
        },
        {
            inverted: !perm.team.frameworks.create,
            action: 'create',
            subject: 'team',
            fields: ['frameworks'],
        },
        {
            inverted: !perm.team.frameworks.read,
            action: 'read',
            subject: 'team',
            fields: ['frameworks'],
        },
        {
            inverted: !perm.team.frameworks.update,
            action: 'update',
            subject: 'team',
            fields: ['frameworks'],
        },
        {
            inverted: !perm.team.frameworks.delete,
            action: 'delete',
            subject: 'team',
            fields: ['frameworks'],
        },
    ]

    const ideationRead = {
        action: 'read',
        subject: 'BrainstormDocData',
    }

    if (perm.brand.ideation.read === 'assigned') {
        ;(ideationRead as any).conditions = { creativeStrategist: { $eq: uid } }
    }

    rules.push(ideationRead)

    const ideationUpdate = {
        action: 'update',
        subject: 'BrainstormDocData',
    }

    if (perm.brand.ideation.update === 'assigned') {
        ;(ideationUpdate as any).conditions = { creativeStrategist: { $eq: uid } }
    }

    rules.push(ideationUpdate)

    const creativesRead = {
        action: 'read',
        subject: 'Creative',
    }

    if (perm.brand.creatives.read === 'assigned') {
        ;(creativesRead as any).conditions = { assignedUsers: { $elemMatch: { id: uid } } }
    }

    rules.push(creativesRead)

    const creativesUpdate = {
        action: 'update',
        subject: 'Creative',
    }

    if (perm.brand.creatives.update === 'assigned') {
        ;(creativesUpdate as any).conditions = { assignedUsers: { $elemMatch: { id: uid } } }
    }

    rules.push(creativesUpdate)

    const viewBrands = {
        action: 'read',
        subject: 'BrandData',
    }

    if (perm.team.brands.read === 'assigned') {
        ;(viewBrands as any).conditions = { assignedUsers: { $elemMatch: { id: uid } } }
    }

    rules.push(viewBrands)

    return createMongoAbility(rules)
}

const AbilityProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { permissions } = usePermissions()
    const { userDB } = useAuth()
    const [ability, setAbility] = useState<AnyAbility | null>(newUserAbility())

    useEffect(() => {
        if (userDB && permissions) {
            setAbility(defineRulesForUser(userDB.uid, permissions))
        }
    }, [userDB, permissions])
    if (ability) {
        return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    } else {
        return (
            <div className="d-flex w-100 vh-100 justify-content-center py-5 align-items-center flex-column">
                <Spinner />
            </div>
        )
    }
}

export default AbilityProvider
